import React from 'react';
import { Instagram } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-white shadow-inner mt-auto">
      <div className="max-w-6xl mx-auto px-4 py-6">
        <div className="flex justify-between items-center">
          <div>
            <span className="text-gray-500">© 2025 Orlando Service Hub. All rights reserved.</span>
          </div>
          <div>
            <a 
              href="https://instagram.com/orlandoservicehub" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-gray-500 hover:text-blue-600 flex items-center gap-2"
            >
              <Instagram size={20} />
              <span>@orlandoservicehub</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
